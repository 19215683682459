/* eslint-disable sort-keys */
const theme = {
  /**
   * Don't add to this without adding to our design library
   */
  colors: {
    darkBlue: '#23293E',
    dartGray: '#a8a8a8',
    dimGray2: '#707070',
    lightGray: '#e9ecef',
    alabaster: '#FCFCFC',
    white: '#fff',
    show: '#f1f1f1',

    // Alert colors
    orange: '#FA8C16',
    orange60: '#f7b16e',
    orange10: '#f6ebe2',

    red: '#F5222D',
    red60: '#f46d77',
    red10: '#f5e1e3',
    // NEW PALETTE

    // BLUE
    blue: '#23293f',
    blue60: '#7b7f8b',
    blue30: '#bdbfc5',
    blue16: '#dcdde0',
    blue06: '#f2f2f3',

    // CORAL

    coral: '#F3786C',
    coral60: '#f8aea7',
    coral40: '#fac9c4',
    coral20: '#fde4e2',
    coral10: '#fef1f0',

    // TEAL

    teal: '#6CCBDA',
    teal60: '#a7e0e9',
    teal40: '#c4eaf0',
    teal20: '#e2f5f8',
    teal10: '#f0fafb',

    // BLACK

    trueBlack: '#000000',
    black: '#222222',
    blackL32: '#525252',
    blackL42: '#707070',
    blackL44: '#707070',
    blackL72: '#B7B7B7',

    // GRAY

    gray: '#d9d9d9',
    whiteSmoke: '#F5F6F7',
    snow: '#fafafa',

    // YELLOW

    yellow: '#F4EB51',
  },
  fonts: {},
  sizes: {},

  // Make sure you update app/javascript/web/styles/theme.js formDefaultColors when editing these colors
  formDefaultColors: {
    pageBackgroundColor: '#FCFCFC',
    sectionBackgroundColor: '#FCFCFC',
    buttonColor: '#23293e',
    fontColor: '#222222',
    buttonFontColor: '#ffffff',
    fieldOutlineColor: '#d9d9d9',
    fieldBackgroundColor: '#fcfcfc',
    fieldFontColor: '#222222',
    placeHolderColor: '#B7B7B7',
  },

  vrDefaultColors: {
    progressBarColor: '#F3786C',
    buttonColor: '#23293E',
    backgroundColor: '#ffffff',
    fontColor: '#23293E',
    fieldOutlineColor: '#d9d9d9',
    registeredColor: '#52C41A',
    unRegisteredColor: '#F5222D',
  },
};

export default theme;
