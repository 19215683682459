const htmlFontSize =
  parseInt(window.getComputedStyle(document.documentElement).getPropertyValue('font-size')) || 16;

const pxToRem = pixels => {
  if (isNaN(pixels)) throw Error('You must pass a number to use the pxToRem util');

  return `${pixels / htmlFontSize}rem`;
};

export default pxToRem;
