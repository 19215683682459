import { getActivityTypeForPage } from '@admin/components/ActivitiesPage/utils';
import _ from 'lodash';
import * as React from 'react';
import { isSurveyActivity } from './components/Survey/helpers';

/*
 * Activity object contains some fields that are not supposed to be submitted to the
 * when creatating a new/updating existing action. This mask object deals with only
 * keeping the list of keys that are supposed to be submitted back to the backed.
 *
 * Update this object when permitted key is added on activity.
 *
 * Derived keys such as folders that get submitted back as `folder_ids` are handled
 * separately as `initialValues`.
 */
const allowedMaskObject = {
  activity_custom_field_ids: null,
  activity_custom_fields_attributes: null,
  activity_incentives_attributes: null,
  activity_scripts_attributes: null,
  activity_tag_ids: null,
  activity_tags_attributes: null,
  activity_user_lists: null,
  activity_user_lists_attributes: null,
  additional_details: null,
  address: null,
  autofilters: null,
  branch_url: null,
  bucket_size: null,
  button_text: null,
  city: null,
  contact_list: null,
  contact_list_id: null,
  description: null,
  dial_authorizer_user_ids: null,
  ends_at: null,
  go_to_link: null,
  id: null,
  keep_assignments: null,
  locale: null,
  locations_attributes: null,
  media_content_type: null,
  media_file_name: null,
  media_file_size: null,
  media_updated_at: null,
  media_url: null,
  modes_permitted: null,
  outreach_preferred_area_codes: null,
  paid_by: null,
  pinned: null,
  privacy_setting: null,
  publish_at: null,
  registrations_goal_amount: null,
  registrations_goal_label: null,
  send_media_url_in_initial_message: null,
  share_link: null,
  share_text: null,
  show_contacts_on_leaderboard_by_default: null,
  starts_at: null,
  state_abbrev: null,
  survey_activity_form_attributes: null,
  survey_activity_form_submissions: null,

  terms: null,
  terms_link: null,
  title: null,
  total_sent_to: null,
  total_sent_to_lists: null,
  type: null,
  user_lists: null,
  zip_code: null,
};

/**
 * This function acts as a whitelist for activity parameters that will be submitted to
 * activity update endpoint (PATCH api/v2/campaigns/:cid/activities/:id)
 */
function filterAllowedActivityParams(activity) {
  const allowedKeys = Object.keys(allowedMaskObject);
  return Object.fromEntries(Object.entries(activity).filter(([key]) => allowedKeys.includes(key)));
}

/**
 * Some actions need specific fields with default values to be provided.
 * This funcitons returns those fields
 */
const getNewActivitySpecificInitialParams = activityType => {
  const specificValues = {
    AssignedCanvassingActivity: { keep_assignments: true },
    default: {},
  };

  return specificValues[activityType] ?? specificValues.default;
};

/**
 *
 * Survey / FormActivity has different endpoints, and completely different data structure,
 * so it needs initialValues that differ from regular ones.
 */
export const getSurveyNewRecordInitialObject = campaign => ({
  activity_incentives_attributes: [],
  activity_questions_attributes: [],
  automated_text_activity_id: null,
  button_text: 'Start',
  description: null,
  folder_ids: [],
  hero_img_content_type: null,
  hero_img_file_name: null,
  hero_img_file_size: null,
  hero_img_updated_at: null,
  hero_img_url: null,
  media_content_type: null,
  media_file_name: null,
  media_file_size: null,
  media_updated_at: null,
  media_url: null,
  paid_by: null,
  privacy_setting: 'private',
  settings: {
    allow_sharing: true,
    background_color: null,
    opt_in_agreement_required: false,
    secondary_color: null,
    thank_you_message: null,
    thank_you_title: null,
  },
  survey_activity_form_attributes: {
    campaign_id: campaign.id,
    date_of_birth: true,
    email: true,
    first_name: true,
    form_submission_message: null,
    last_name: true,
    phone: true,
    zip_code: false,
  },
  terms: null,
  terms_link: null,
  title: null,
});

const getSurveysActivityInitialValues = (activity, campaign, newRecord) => {
  if (newRecord) return getSurveyNewRecordInitialObject(campaign);

  return activity;
};

export const getDefaultInitialScript = campaignId => ({
  campaign_id: campaignId,
  media_url: '',
  mode: 'sms',
  name: '',
  opt_out_language: false,
  script: '',
  script_type: 'initial',
});

function useInitialValues(activity, campaign, newRecord, newActionType) {
  const defaultInitialScript = React.useMemo(
    () => getDefaultInitialScript(campaign.id),
    [campaign.id],
  );

  const initialValues = React.useMemo(() => {
    const actionType = getActivityTypeForPage(newActionType);

    //survey Activity has a different endpoint, so data is completely different
    if (isSurveyActivity(actionType))
      return getSurveysActivityInitialValues(activity, campaign, newRecord);

    if (newRecord) {
      return {
        activity_custom_field_ids: [],
        activity_incentives_attributes: [],
        activity_scripts_attributes: [defaultInitialScript],
        activity_tag_ids: [],
        autofilter_in_district: false,
        autofilter_in_swing_state: false,
        autofilter_party: null,
        autofilter_voter_frequency: null,
        button_text: 'Start',
        confirmRequirements: false,
        contact_list_id: '',
        description: '',
        folder_ids: [],
        go_to_link: '',
        media_content_type: '',
        media_file_name: '',
        media_file_size: '',
        media_url: '',
        outreach_preferred_area_codes: [],
        paid_by: '',
        pinned: false,
        privacy_setting: 'private',
        publish_at: '',
        share_link: '',
        share_text: '',
        show_contacts_on_leaderboard_by_default: true,
        title: '',
        type: actionType,
        ...getNewActivitySpecificInitialParams(actionType),
      };
    }

    return {
      ...filterAllowedActivityParams(activity),
      activity_custom_field_ids: _.map(activity.activity_custom_fields, 'custom_field_id'),
      activity_incentives_attributes: activity?.activity_incentives_attributes,
      activity_tag_ids: _.map(activity.activity_tags, 'tag_id'),
      autofilter_in_district: activity?.autofilters?.in_district || false,
      autofilter_in_swing_state: activity?.autofilters?.in_swing_state || false,
      autofilter_party: activity?.autofilters?.party || null,
      autofilter_voter_frequency: activity?.autofilters?.voter_frequency || null,
      automated_text_activity_id: activity?.automated_text_activity_id,
      automated_texting_enabled: !!activity?.automated_text_activity_id,
      confirmRequirements: true,
      // Folders need to be remapped into folder_ids
      folder_ids: activity?.folders?.map(f => f.id),
      hero_img_content_type: activity?.hero_img_content_type,
      hero_img_file_name: activity?.hero_img_file_name,
      hero_img_file_size: activity?.hero_img_file_size,
      hero_img_updated_at: activity?.hero_img_updated_at,
      hero_img_url: activity?.hero_img_url,
    };
  }, [newActionType, activity, campaign, newRecord, defaultInitialScript]);

  return { defaultInitialScript, initialValues };
}

export default useInitialValues;
