// MTS - The purpose of this function is to provide a wrapper
// around any error monitoring software we currently use or
// will use in the future.
import * as Sentry from '@sentry/browser';

const notDev = process.env.NODE_ENV !== 'development';

// MTS - I'm going to test this function out for a while to see if it
// allows us to get more data into Sentry.
class HandledError extends Error {
  constructor(errMessage, data) {
    // Passes errMessage to the Error super class,
    // similar to call new Error(errMessage).
    super(errMessage);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HandledError);
    }

    this.name = 'HandledError';
  }
}

const sendError = (message, errObj = {}, extraData = {}) => {
  function getResponse() {
    if (errObj?.response?.data) {
      return JSON.stringify(errObj.response.data);
    }
    return 'no backend message';
  }

  if (notDev) {
    addBreadcrumb({
      category: 'sendError Breadcrumb',
      data: {
        ...errObj,
        ...extraData,
      },
      level: 'error',
      message,
    });

    let thisError;

    // MTS - We check to see if the errObj is actually a javascript error.
    // Sometimes it isn't.
    if (errObj instanceof Error) {
      // This overrides the error message to something that is
      // easily readable in Sentry.
      thisError = new Error(message, errObj);
    } else {
      thisError = new HandledError(message, { ...errObj, ...extraData });
    }

    Sentry.captureException(thisError, {
      extra: {
        message,
        ...errObj,
        ...extraData,
      },
    });
  } else {
    console.error({
      backendMessage: getResponse(),
      errObj,
      extraData,
      message,
    });
  }
};

export const addBreadcrumb = ({ category = 'default', data = {}, message = '', level = 'log' }) => {
  // valid level values: 'log', 'error', 'fatal', 'info', 'warning', 'critical'
  if (notDev) {
    Sentry.addBreadcrumb({
      category,
      data,
      level,
      message,
    });
  }
};

export default sendError;
