import axios from 'axios';
import { routes } from './routes';

export const billing = {
  addPaymentMethod: ({ campaignId }) => {
    return axios.post(`${routes.campaigns}/${campaignId}/add_payment_method`);
  },
  createStripeSubscription: ({ campaignId, plan }) => {
    return axios.post(`${routes.campaigns}/${campaignId}/create_stripe_subscription`, { plan });
  },
  getBillingHistory: async ({ campaignId }) => {
    return axios.get(`${routes.campaigns}/${campaignId}/billing_history`);
  },
  getUpcomingInvoice: async ({ campaignId }) => {
    return axios.get(`${routes.campaigns}/${campaignId}/upcoming_invoice`);
  },
  setDefaultPaymentMethod: ({ campaignId, paymentMethodId }) => {
    return axios.post(`${routes.campaigns}/${campaignId}/set_default_payment_method`, {
      payment_method_id: paymentMethodId,
    });
  },
  setupStripe: async ({ subscriptionId }) => {
    return axios.post(`${routes.subscriptions}/${subscriptionId}/setup_stripe`);
  },
  updatePlan: async ({ subscriptionId, planSlug }) => {
    return axios.patch(`${routes.subscriptions}/${subscriptionId}/update_plan`, {
      plan_slug: planSlug,
    });
  },
  updateSubscription: ({ subscription_id, data }) => {
    return axios.patch(`${routes.subscriptions}/${subscription_id}`, data);
  },
};
