import styled, { css } from 'styled-components';
import pxToRem from '@admin/utils/pxToRem';
import theme from '@admin/styles/theme';

export const Flex = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  gap: ${({ gap }) => gap || '0px'};
  flex-wrap: ${({ flexWrap }) => flexWrap || 'no-wrap'};

  ${({ scrollable }) =>
    scrollable &&
    css`
      overflow-y: auto;

      ::-webkit-scrollbar {
        width: 4px;
        background-color: ${theme.colors.dimGray2}1A;
      }

      ::-webkit-scrollbar-thumb {
        background-color: ${theme.colors.dimGray2};
      }

      /* for mozilla */
      scrollbar-color: ${theme.colors.dimGray2} ${theme.colors.dimGray2}1A;
      scrollbar-width: thin;
    `}
`;

export const SpacedBlock = styled.div`
  padding: 0;
  margin-top: ${({ marginTop }) => marginTop || '0'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};
  margin-left: ${({ marginLeft }) => marginLeft || '0'};
  margin-right: ${({ marginRight }) => marginRight || '0'};
`;

export const FormBlock = styled.div`
  margin-bottom: ${pxToRem(50)};
  width: 50%;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const Divider = styled.div`
  background-color: #f5f6f7;
  ${({ horizontal }) =>
    horizontal &&
    `
    width: 100%;
    height: 1px;
`}

  ${({ vertical, isInFlex }) =>
    vertical &&
    `
    ${isInFlex ? 'align-self: stretch;' : 'height: 100%;'}
    width: 1px;
`}
`;
