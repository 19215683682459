import React, { useEffect, useState } from 'react';

import styled, { css } from 'styled-components';
import theme from '@admin/styles/theme';
import PropTypes from 'prop-types';

import { Input, Select } from 'antd';
import { Flex } from './LayoutComponents';
import { ImpactiveButton } from './ImpactiveButtons';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const FlexWrapper = styled(Flex)`
  padding: 7px 30px;

  @media (max-width: 840px) {
    flex-wrap: wrap-reverse;
    gap: 20px;
    padding: 7px 15px;
  }
`;

const FlexText = styled(Flex)`
  color: ${theme.colors.blackL42};
  font-size: 14px;
  white-space: nowrap;
  width: unset;
`;

const StyledInput = styled(Input)`
  &&& {
    height: 32px;
    margin: 0 12px;
    width: 52px;
    text-align: center;
  }
`;

const StyledSelect = styled(Select)`
  &&& {
    height: 32px;
    margin: 0 12px;
    width: 68px;
  }
`;

const StyledButton = styled(ImpactiveButton)`
  &&& {
    min-height: 34px;
    ${({ $isPrev }) =>
      $isPrev
        ? css`
            margin-right: 50px;
            @media (max-width: 700px) {
              margin-right: 3%;
            }
          `
        : css`
            flex-direction: row-reverse;
            margin-left: 50px;
            @media (max-width: 700px) {
              margin-left: 3%;
            }
          `}

    @media (max-width: 550px) {
      padding: 0 0.5rem;
      span {
        margin: 0 !important;
        &:last-child {
          display: none;
        }
      }
    }
  }
`;

const TablePagination = ({
  page: currentPage,
  pages,
  pageSize,
  canNext,
  onPageChange,
  pageSizeOptions,
  onPageSizeChange,
  canPrevious,
  showPageJump,
  showPageSizeOptions,
  total,
  scrollToTop,
  ...props
}) => {
  const [page, setPage] = useState(currentPage);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const getSafePage = page => {
    if (isNaN(page)) {
      page = currentPage;
    }
    if (canNext) return page;

    return Math.min(Math.max(page, 0), (pages ?? 1) - 1);
  };

  const changePage = newPage => {
    newPage = getSafePage(newPage);
    setPage(newPage);
    if (currentPage !== newPage) {
      onPageChange(newPage);
      if (scrollToTop) {
        document.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
      }
    }
  };

  const applyPage = e => {
    if (e) {
      e.preventDefault();
    }
    changePage(page === '' ? currentPage : page);
  };

  const onPageJump = e => {
    const val = e.target.value;
    const page = val - 1;
    if (val === '') {
      return setPage(val);
    }

    setPage(getSafePage(page));
  };

  return (
    <FlexWrapper>
      {showPageSizeOptions && (
        <FlexText>
          Rows per page
          <StyledSelect onChange={value => onPageSizeChange(Number(value))} value={pageSize}>
            {pageSizeOptions.map((option, i) => (
              <Select.Option key={i} value={option}>
                {option}
              </Select.Option>
            ))}
          </StyledSelect>
          {!!total && `of ${total}`}
        </FlexText>
      )}
      <Flex justifyContent="center" style={{ marginLeft: 10 }}>
        <StyledButton
          icon={<LeftOutlined />}
          onClick={() => changePage(page - 1)}
          disabled={!canPrevious}
          $isPrev
        >
          Prev
        </StyledButton>

        {showPageJump ? (
          <FlexText>
            Page
            <StyledInput
              onChange={onPageJump}
              value={page === '' ? '' : page + 1}
              onBlur={applyPage}
              onKeyPress={e => e.key === 'Enter' && applyPage()}
            />
            of {pages}
          </FlexText>
        ) : (
          <FlexText>Page: {page + 1}</FlexText>
        )}

        <StyledButton
          icon={<RightOutlined style={{ marginLeft: 10 }} />}
          onClick={() => changePage(page + 1)}
          disabled={!canNext}
        >
          Next
        </StyledButton>
      </Flex>
    </FlexWrapper>
  );
};

TablePagination.propTypes = {
  canNext: PropTypes.bool,
  canPrevious: PropTypes.bool,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pages: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageSizeOptions: PropTypes.array,
  scrollToTop: PropTypes.bool,
  showPageJump: PropTypes.bool,
  showPageSizeOptions: PropTypes.bool,
  total: PropTypes.number,
};

export default TablePagination;
