import { Button } from 'antd';

import styled from 'styled-components';
import theme from '@admin/styles/theme';
import pxToRem from '@admin/utils/pxToRem';

export const ImpactiveButton = styled(Button)`
  &&& {
    min-height: 42px;
    font-size: 0.875rem;
    border-radius: 4px;
    padding: 0 2rem;
    ${({ type, disabled }) =>
      type === 'primary' &&
      !disabled &&
      `
        color: white;
        background:  ${theme.colors.blue};
      `}

    ${({ size }) =>
      size === 'small' &&
      `
      min-height: 30px !important;
      padding: 0 1rem;
      `}
    &:hover {
      opacity: 0.8;
    }
  }
`;
// This button can be used anywhere there is a link button or link
export const ImpactiveLinkButton = styled.a`
  ${({ inline }) => inline && 'display: inline !important;'}

  ${({ type }) => {
    if (type === 'bolder') {
      return `
        color: ${theme.colors.black};
        font-weight: 500;
      `;
    }

    return `color: ${theme.colors.blackL32};`;
  }}
  display: flex;
  align-items: center;
  font-size: ${pxToRem(14)};

  svg {
    margin-left: 5px;
  }
`;

//this is a button, that looks like a link
export const ImpactiveTextButton = styled(Button)`
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  transition: color 0.2s ease-in-out;

  display: flex;
  align-items: center;

  font-size: ${pxToRem(14)};
  text-decoration: underline;
  color: ${theme.colors.blackL32};

  svg {
    margin-left: 5px;
    color: ${theme.colors.blackL32};
  }

  &:hover {
    svg {
      color: ${theme.colors.coral};
    }
    text-decoration: underline;
    color: ${theme.colors.coral};
  }
`;
