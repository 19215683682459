export const isSurveyActivity = name => ['SurveyActivity', 'build_list'].includes(name);

export const labelsOptions = [
  {
    label: 'Start',
    value: 'Start',
  },
  {
    label: 'Empieza',
    value: 'Empieza',
  },
  {
    label: 'Sign Up',
    value: 'Sign Up',
  },
  {
    label: 'Donate Now',
    value: 'Donate Now',
  },
  {
    label: 'Begin Survey',
    value: 'Begin Survey',
  },
  {
    label: 'RSVP',
    value: 'RSVP',
  },
  {
    label: 'Pledge',
    value: 'Pledge',
  },
];

export const fontOptions = [
  { font: 'BasicSans', label: 'Basic Sans', value: 'BasicSans' },
  {
    font: 'Helvetica, sans-serif',
    label: 'Helvetica',
    value: 'Helvetica, sans-serif',
  },
  { font: 'Arial, sans-serif', label: 'Arial ', value: 'Arial, sans-serif' },
  {
    font: 'Arial Black, sans-serif',
    label: 'Arial Black',
    value: 'Arial Black, sans-serif',
  },
  { font: 'Verdana, sans-serif', label: 'Verdana', value: 'Verdana, sans-serif' },
  { font: 'Tahoma, sans-serif', label: 'Tahoma', value: 'Tahoma, sans-serif' },
  {
    font: 'Trebuchet MS, sans-serif',
    label: 'Trebuchet MS',
    value: 'Trebuchet MS, sans-serif',
  },
  { font: 'Impact, sans-serif', label: 'Impact', value: 'Impact, sans-serif' },
  {
    font: 'Gill Sans, sans-serif',
    label: 'Gill Sans',
    value: 'Gill Sans, sans-serif',
  },
  {
    font: 'Times New Roman, serif',
    label: 'Times New Roman',
    value: 'Times New Roman, serif',
  },
  { font: 'Georgia, serif', label: 'Georgia', value: 'Georgia, serif' },
  { font: 'Palatino, serif', label: 'Palatino', value: 'Palatino, serif' },
  { font: 'Baskerville, serif', label: 'Baskerville', value: 'Baskerville, serif' },
  {
    font: 'Andalé Mono, monospace',
    label: 'Andalé Mono',
    value: 'Andalé Mono, monospace',
  },
  { font: 'Courier, monospace', label: 'Courier', value: 'Courier, monospace' },
  { font: 'Lucida, monospace', label: 'Lucida', value: 'Lucida, monospace' },
  { font: 'Monaco, monospace', label: 'Monaco', value: 'Monaco, monospace' },
  {
    font: 'Bradley Hand, cursive',
    label: 'Bradley Hand',
    value: 'Bradley Hand, cursive',
  },
  {
    font: 'Brush Script MT, cursive',
    label: 'Brush Script MT',
    value: 'Brush Script MT, cursive',
  },
  { font: 'Luminari, fantasy', label: 'Luminari', value: 'Luminari, fantasy' },
  {
    font: 'Comic Sans MS, cursive',
    label: 'Comic Sans MS',
    value: 'Comic Sans MS, cursive',
  },
];
