export const routes = {
  activities: '/api/v2/campaigns/:campaign_id/activities',
  activity_links: '/api/v2/activities/:activity_id/campaign_links',
  activity_scripts: '/api/v2/campaigns/:campaign_id/activities/:activity_id/scripts',
  admin_activities: '/api/v2/admin/campaigns/:campaign_id/activities',
  approvals: '/api/v2/approvals',
  auth: '/api/v2/users',
  broadcast_activities: '/api/v2/broadcast_activities',
  broadcastActivities: '/api/v2/campaigns/:campaign_id/broadcast_activities',
  campaign_links: '/api/v2/campaigns/:campaign_id/campaign_links',
  campaigns: '/api/v2/campaigns',
  canvassing_activities: '/api/v2/canvassing_activities',
  categories: '/api/v2/categories',
  causes: '/api/v2/causes',
  contact_activities_metrics: '/api/v2/activities/:activity_id/contact_activities_metrics',
  contact_lists: '/api/v2/contact_lists',
  contacts: '/api/v2/contacts',
  invitations: '/api/v2/admin/invitations',
  messages: '/api/v2/admin/message_templates',
  opt_in_forms: '/api/v2/campaigns/:campaign_id/opt_in_forms',
  registrations: '/api/v2/admin',
  report_templates: '/api/v2/report_templates',
  scripts: '/api/v2/campaigns/:campaign_id/scripts/',
  subscriptions: '/api/v2/subscriptions',
  survey_activities: '/api/v2/campaigns/:campaign_id/survey_activities',
  tags: '/api/v2/tags',
  text_a_list_activities: '/api/v2/text_a_list_activities',
  twilio: '/api/v2/campaigns/:campaign_id/twilio_registration',
  twilioAdmin: '/api/v2/super_admin_twilio_registration',
  user: '/api/v2/user',
  user_campaigns: '/api/v2/user_campaigns',
  user_contact_activities_metrics:
    '/api/v2/activities/:activity_id/user_contact_activities_metrics',
  user_lists: '/api/v2/user_lists',
  van_integration: '/api/v2/admin/van_integration',
};
